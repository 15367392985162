import { useEffect, useState } from "react";

const useMapMove = (map) => {
  const [lng, setLng] = useState(9.0815);
  const [lat, setLat] = useState(45.5221);
  const [zoom, setZoom] = useState(16);

  useEffect(() => {
    if (!map) return;

    map.on("move", () => {
      setLng(map.getCenter().lng.toFixed(4));
      setLat(map.getCenter().lat.toFixed(4));
      setZoom(map.getZoom().toFixed(2));
    });
  });

  return {
    lng,
    lat,
    zoom,
  };
};

export default useMapMove;
