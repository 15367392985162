const getEnvApi = () => {
  switch(process.env.REACT_APP_ENV) {
    case 'production':
      return 'https://fieramilano-wayfinding-prod.crispybacon.tech';
    case 'production-mock':
      return 'https://api-prod.fmwfpoc.crispybacon.dev';
    case 'staging':
      return 'https://api.fmwfpoc.crispybacon.dev';
    default:
      return 'http://127.0.0.1:3000';
  }
};

const server = getEnvApi();

const findwayastar = async (exhibitionId, points) => {
  const response = await fetch(`${server}/wayfinding/findway`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-exhibition-id": exhibitionId,
    },
    body: JSON.stringify(points),
  });
  const json = await response.json();
  return json;
};

const indexing = async (exhibitionId) => {
  const response = await fetch(`${server}/indexing/ingest`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-exhibition-id": exhibitionId,
    },
  });
  const json = await response.json();
  return json;
};

const extract = async (exhibitionId, type, floor) => {
  const response = await fetch(`${server}/indexing/extract/${type}/${floor}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-exhibition-id": exhibitionId,
    },
  }).then((response) => {
    if (response.status >= 400 && response.status < 600) {
      throw new Error("Bad Response from server");
    }
    return response;
  });
  const json = await response.json();
  return json;
};

const emptyCache = async () => {
  await fetch(`${server}/indexing/cache`, {
    method: "DELETE"
  })
}

export { findwayastar, indexing, extract, emptyCache };
