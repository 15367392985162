import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { saveAs } from "file-saver";
import { useEffect, useRef, useState } from "react";
import { bboxPolygon, featureCollection, point as turfPoint } from "turf";
import { extract, findwayastar } from "../services/map.service";
import useMapMove from "./useMapMove";

mapboxgl.accessToken =
  "pk.eyJ1IjoiemlvbWFyY28iLCJhIjoiY2t6cTBjeTNmM21qcjJ2bnlocWV6OHB1YyJ9.AIc8rJ-pp8QQ1xFs44MdNQ";

const mainRoadJson = require("../data/line_corso_italia.json");

const emptyGEO = {
  type: "Feature",
  properties: {},
  geometry: { type: "LineString", coordinates: [] },
};
const poiColors = [
  "match",
  ["get", "category"],
  3, // Exposition Area
  "#546e7a",
  19, // Parcheggio dipendenti
  "#1565c0",
  25,
  "#1565c0",
  26,
  "#1565c0",
  27, // Porta
  "#00796b",
  28, // Ascensore
  "#004d40",
  5, // Reception
  "#bf360c",
  "#b0bec5",
];

const pathColors = [
  "match",
  ["get", "floor"],
  0,
  "#0d47a1",
  1,
  "#4fc3f7",
  "#b0bec5",
];

const useMap = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const clickEnabled = useRef(true);
  const [point, setPoint] = useState();
  const [draw, setDraw] = useState(false);
  const [mouseMove, setMouseMove] = useState(null);
  const [info, setInfo] = useState("");
  const pathfindingPoints = useRef([]);
  const customPoints = useRef([]);
  const [startingFloor, setStartingFloor] = useState(0);
  const [endingFloor, setEndingFloor] = useState(0);
  const [floor, setFloor] = useState(0);
  const { lng, lat, zoom } = useMapMove(map.current);
  const [exhibitionId, setExhibitionId] = useState(36821);
  const [refreshData, setRefreshData] = useState(false);

  const setExhibition = (id) => {
    setExhibitionId(id);
    refresh();
  };

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [9.079086011885124, 45.52012299732081],
      zoom: zoom,
    });
  });

  const updateCustomPoints = () => {
    map.current
      .getSource("custom-points")
      .setData(featureCollection(customPoints.current));
  };

  const toggleDraw = () => {
    if (customPoints.current.length !== 0) {
      const blob = new Blob([JSON.stringify(customPoints.current)], {
        type: "application/json:charset=utf-8",
      });
      saveAs(blob, "customPoints.json");

      customPoints.current = [];
      updateCustomPoints();
    }
    setDraw(!draw);
  };

  useEffect(() => {
    if (!mouseMove) return;

    const features = map.current.queryRenderedFeatures(mouseMove.point, {
      layers: ["stands-layer-0", "stands-layer-1", "from", "to"].filter(
        (e) => !!map.current.getLayer(e)
      ),
    });
    if (features.length !== 0) {
      features.forEach((element) => {
        // console.log(`${JSON.stringify(element)}`);
      });
    }
  }, [mouseMove]);

  useEffect(() => {
    if (!point) return;

    if (!draw) {
      console.log(`${point.wrap()}`);

      const handleFindway = async () => {
        try {
          const path = await findwayastar(exhibitionId, {
            from: pathfindingPoints.current[0],
            to: pathfindingPoints.current[1],
          });

          map.current.getSource("path-lines").setData(path);
        } finally {
          pathfindingPoints.current = [];
          clickEnabled.current = true;
        }
      };

      if (pathfindingPoints.current.length === 0) {
        const geopoint = turfPoint([point.lng, point.lat], {
          floor: startingFloor,
        });
        pathfindingPoints.current.push(geopoint);
        map.current.getSource("from").setData(geopoint);
      } else {
        const geopoint = turfPoint([point.lng, point.lat], {
          floor: endingFloor,
        });
        pathfindingPoints.current.push(geopoint);
        map.current.getSource("to").setData(geopoint);
        // Fetch path and then reset
        clickEnabled.current = false;
        handleFindway();
      }
    } else {
      const geopoint = turfPoint([point.lng, point.lat], {
        index: customPoints.current.length,
      });
      customPoints.current.push(geopoint);
      updateCustomPoints();
    }
  }, [point]);

  useEffect(() => {
    const handleExtractPavillion = async (floor) => {
      try {
        const pavillions = await extract(exhibitionId, "pavillion", floor);
        map.current.getSource(`pavillions-${floor}`).setData(pavillions);
      } catch (err) {
        console.log(err);
      }
    };

    const handleExtractStands = async (floor) => {
      try {
        const stands = await extract(exhibitionId, "stands", floor);
        map.current.getSource(`stands-${floor}`).setData(stands);
      } catch (err) {
        console.log(err);
      }
    };

    const handleExtractPois = async (floor) => {
      try {
        const pois = await extract(exhibitionId, "poi", floor);
        map.current.getSource(`pois-${floor}`).setData(pois);

        for (const feature of pois.features) {
          const categoryId = feature.properties.category;
          const text = feature.properties.categoryName;

          if (!categoryId && !text) {
            break;
          }

          const layerID = `poi-${categoryId}`;
          if (!map.current.getLayer(layerID)) {
            // console.log(`Layer ${layerID}, ${text}`);
            map.current.addLayer({
              id: layerID,
              type: "symbol",
              source: "pois",
              layout: {
                "icon-size": 1,
                "icon-allow-overlap": true,
                "text-field": ["get", "name"],
                "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
                "text-size": 9,
                "text-transform": "uppercase",
                "text-letter-spacing": 0.05,
                "text-offset": [0, 1.5],
              },
              paint: {
                "text-color": poiColors,
                "text-halo-color": "#fff",
                "text-halo-width": 1,
              },
              filter: ["==", "category", categoryId],
            });
          }
        }
      } catch (err) {
        // console.log(err);
      }
    };

    if (exhibitionId !== 0 && refreshData) {
      handleExtractPavillion(0);
      handleExtractPavillion(1);
      handleExtractStands(0);
      handleExtractStands(1);
      handleExtractPois(0);
      handleExtractPois(1);
    }
  }, [exhibitionId, refreshData]);

  useEffect(() => {
    if (!map.current) return;
    const visibility = (layerFloor) => {
      return floor === layerFloor ? "visible" : "none";
    };

    const setVisibility = (layerName, layerFloor) => {
      map.current.setLayoutProperty(
        `${layerName}-layer-${layerFloor}`,
        "visibility",
        visibility(layerFloor)
      );
    };

    try {
      setVisibility("pavillions", 0);
      setVisibility("pavillions", 1);
      setVisibility("stands", 0);
      setVisibility("stands", 1);
      setVisibility("pois", 0);
      setVisibility("pois", 1);
    } catch (err) {
      console.log(err);
    }
  }, [floor]);

  const addSource = (name, floor) => {
    map.current.addSource(`${name}-${floor}`, {
      type: "geojson",
      data: emptyGEO,
    });
  };

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize

    map.current.on("mousemove", (event) => {
      setMouseMove(event);
    });

    map.current.on('click', `pois-layer-0`, (e) => {
      e?.stopPropagation?.();
      e?.stopImmediatePropagation?.();
      e.preventDefault();
      alert(JSON.stringify(e?.features?.[0], undefined, 4));
    });
    
    map.current.on('click', `pois-layer-1`, (e) => {
      e?.stopPropagation?.();
      e?.stopImmediatePropagation?.();
      e.preventDefault();
      alert(JSON.stringify(e?.features?.[0], undefined, 4));
    });

    map.current.on("click", (event) => {
      clickEnabled.current && setPoint(event.lngLat);
    });

    map.current.on("load", () => {
      // const styleJson = map.current.getStyle();
      // console.log("Map style: " + JSON.stringify(styleJson));

      // Sources

      map.current.addSource("path-lines", {
        type: "geojson",
        data: emptyGEO,
      });
      addSource("pavillions", 0);
      addSource("pavillions", 1);
      addSource("stands", 0);
      addSource("stands", 1);
      addSource("pois", 0);
      addSource("pois", 1);

      const siteBounds = bboxPolygon([
        9.088053703308104, 45.51296331391637, 9.06646728515625,
        45.52739642405857,
      ]);

      map.current.addLayer({
        id: "boundaries",
        type: "fill",
        source: {
          type: "geojson",
          data: siteBounds,
        },
        layout: {},
        paint: {
          "fill-color": "#b8e68a",
          "fill-opacity": 0.1,
        },
      });
      // Point FROM
      map.current.addLayer({
        id: "from",
        type: "circle",
        source: {
          type: "geojson",
          data: emptyGEO,
        },
        layout: {},
        paint: {
          "circle-radius": 8,
          "circle-color": "#0d47a1",
        },
      });
      // Point TO
      map.current.addLayer({
        id: "to",
        type: "circle",
        source: {
          type: "geojson",
          data: emptyGEO,
        },
        layout: {},
        paint: {
          "circle-radius": 8,
          "circle-color": "#dd2c00",
        },
      });
      // Custom Points
      map.current.addLayer({
        id: "custom-points",
        type: "circle",
        source: {
          type: "geojson",
          data: {},
        },
        layout: {},
        paint: {
          "circle-radius": 8,
          "circle-color": "#00796b",
        },
      });

      // Path
      map.current.addLayer({
        id: "path",
        type: "line",
        source: "path-lines",
        layout: {
          "line-cap": "round",
          "line-join": "round",
        },
        paint: {
          "line-color": pathColors,
          "line-width": 8,
        },
      });
      // Corso Italia, Ponte dei mari (Main Road)
      map.current.addLayer({
        id: "mainRoad",
        type: "line",
        source: {
          type: "geojson",
          data: mainRoadJson,
        },
        layout: {
          "line-cap": "round",
          "line-join": "round",
        },
        paint: {
          "line-color": "#1565c0",
          "line-width": 2,
        },
      });
      // Pavillions
      map.current.addLayer({
        id: "pavillions-layer-0",
        type: "fill",
        source: "pavillions-0",
        layout: {
          visibility: "visible",
        },
        paint: {
          "fill-color": "#219ebc",
          "fill-opacity": 0.2,
        },
      });
      map.current.addLayer({
        id: "pavillions-layer-1",
        type: "fill",
        source: "pavillions-1",
        layout: {
          visibility: "none",
        },
        paint: {
          "fill-color": "#ef6c00",
          "fill-opacity": 0.2,
        },
      });

      map.current.addLayer({
        id: "pois-layer-0",
        type: "circle",
        source: "pois-0",
        layout: {
          visibility: "visible",
        },
        paint: {
          "circle-radius": 4,
          "circle-color": poiColors,
        },
      });
      map.current.addLayer({
        id: "pois-layer-1",
        type: "circle",
        source: "pois-1",
        layout: {
          visibility: "none",
        },
        paint: {
          "circle-radius": 4,
          "circle-color": poiColors,
        },
      });

      // Stands
      map.current.addLayer({
        id: "stands-layer-0",
        type: "fill",
        source: "stands-0",
        layout: {
          visibility: "visible",
        },
        paint: {
          "fill-color": [
            "case",
            ["boolean", ["feature-state", "clicked"], true],
            "#546e7a", // if selected true, paint in blue
            "#888888", // else paint in gray
          ],
          "fill-opacity": 0.5,
        },
      });
      // Stands
      map.current.addLayer({
        id: "stands-layer-1",
        type: "fill",
        source: "stands-1",
        layout: {
          visibility: "none",
        },
        paint: {
          "fill-color": [
            "case",
            ["boolean", ["feature-state", "clicked"], true],
            "#546e7a", // if selected true, paint in blue
            "#888888", // else paint in gray
          ],
          "fill-opacity": 0.5,
        },
      });

      //load
    });

    map.current.on("idle", () => {
      // console.log("Idle...");
    });
  }, []);

  const refresh = () => {
    setRefreshData(true);
    setTimeout(() => setRefreshData(false), 5000);
  };

  return {
    mapContainer,
    lng,
    lat,
    zoom,
    info,
    mouseMove,
    draw,
    toggleDraw,
    startingFloor,
    setStartingFloor,
    endingFloor,
    setEndingFloor,
    exhibitionId,
    setExhibition,
    floor,
    setFloor,
    refresh,
  };
};

export default useMap;
