import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import useMap from "./hooks/useMap";
import { emptyCache } from "./services/map.service";

// .
function App() {
  const {
    mapContainer,
    lng,
    lat,
    zoom,
    mouseMove,
    draw,
    toggleDraw,
    startingFloor,
    setStartingFloor,
    endingFloor,
    setEndingFloor,
    exhibitionId,
    setExhibition,
    floor,
    setFloor,
    refresh,
  } = useMap();
  const exhibitionRef = useRef();
  const [code, setCode] = useState(exhibitionId);
  const [mouseCoords, setMouseCoords] = useState("");

  useEffect(() => {
    mouseMove && setMouseCoords(`${mouseMove.lngLat.wrap()}`);
  }, [mouseMove]);

  return (
    <div>
      <div className="hud">
        <div className="sidebar">
          Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
        </div>
        <div className="mousecoordinates">{mouseCoords}</div>
        <div className="">
          <button
            onClick={() => {
              refresh();
            }}
          >
            Refresh
          </button>
          <button
            style={{ margin: '10px' }}
            onClick={() => {
              emptyCache();
            }}
          >
            Empty Cache
          </button>
        </div>
        <div className="">
          <input
            title="Exhibition Id"
            ref={exhibitionRef}
            type="number"
            placeholder="Exhibition Id"
            value={code}
            onChange={(e) => setCode(+e.target.value)}
          />
          <button
            onClick={() => {
              setExhibition(exhibitionRef.current.value);
            }}
          >
            Confirm exhibition
          </button>
        </div>
        <div className="container">
          <label>
            Floor:
            <input
              type="checkbox"
              className="floor"
              checked={floor}
              onChange={(e) => setFloor(+e.target.checked)}
            ></input>
          </label>
        </div>
        <div className="container">
          <label>
            Starting floor:
            <input
              type="checkbox"
              className="floor"
              checked={startingFloor}
              onChange={(e) => setStartingFloor(+e.target.checked)}
            ></input>
          </label>
          <label>
            Ending floor:
            <input
              type="checkbox"
              className="floor"
              checked={endingFloor}
              onChange={(e) => setEndingFloor(+e.target.checked)}
            ></input>
          </label>
        </div>
        <div className="container">
          <button
            style={{ backgroundColor: draw ? "#66bb6a" : "#ff5722" }}
            onClick={() => {
              toggleDraw();
            }}
          >
            Draw Points
          </button>
        </div>
      </div>
      <div ref={mapContainer} className="map-container" />
    </div >
  );
}

export default App;
